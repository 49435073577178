import { notification } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { ESuiviObject } from "../../../types/ESuiviObject"
import IFormProps from "../../../types/IFormProps"
import { NDFForm } from "../../shared/form/pages/Form/Form"
import { useDevisContext } from "../context/devis.context"
import { IDevis, IDevisCreate } from "../models/IDevis"
import { DevisAPI } from "../services/Devis.api"
import { useDevis } from "../stores/Devis.store"
import { DevisFormContent } from "./DevisFormContent/DevisFormContent"

export const DevisForm: React.FC<IFormProps<IDevis | IDevisCreate>> = ({
  id,
  isOpen,
  onSubmit,
  onClose,
}) => {
  const [devisId, setDevisId] = useState(id)
  const [loading, setLoading] = useState(true)
  const devis = useDevis()
  const isEdition = !!devisId

  const { getDevis } = useDevisContext()

  const onFormSubmit = async (devisForm: IDevisCreate) => {
    const devis = getDevis()

    const invalidLines = devis.line.filter((line) => !line.isValid())
    if (invalidLines.length) {
      notification.error({
        message: "Erreur",
        description: `Impossible de créer le devis ${devisForm.devisTitle ?? ""}. Les lignes en positions ${invalidLines.map((line) => line.lineNum).join(", ")} sont incomplètes.`,
      })
      return false
    }

    const newDevis: IDevisCreate = {
      ...devisForm,
      ...devis,
      dateExpiration: dayjs(devisForm.dateExpiration ?? "").format("YYYY-MM-DD"),
      datePiece: dayjs(devisForm.datePiece ?? "").format("YYYY-MM-DD"),
      devisInfosClient: devisForm.devisInfosClient ?? "",
      devisCGV: devisForm.devisCGV ?? "",
      devisTitle: devisForm.devisTitle ?? "",
    }

    const isDevisCreated = await DevisAPI.createDevis(newDevis)
      .then((res) => {
        if (res.ok) {
          onSubmit()
          return true
        }
        return false
      })
      .catch((err) => {
        notification.error({
          message: "Erreur",
          description: `Impossible de créer le devis ${newDevis.devisTitle}. Erreur ${err}`,
        })
        return false
      })
      .finally(() => {
        setLoading(false)
      })

    return isDevisCreated
  }

  const handleOnClose = () => {
    setDevisId(undefined)
    onClose()
  }

  return (
    <NDFForm
      title={isEdition ? "Mise à jour" : "Nouveau devis"}
      onClose={handleOnClose}
      onSubmit={onFormSubmit}
      next={() => {
        const currentDevisIndex = devis.findIndex((c) => c.IDDevis === devisId)
        const nextDevis =
          devis.length === currentDevisIndex + 1 ? devis.at(0) : devis.at(currentDevisIndex + 1)
        if (nextDevis) {
          setDevisId(nextDevis.IDDevis)
        }
      }}
      previous={() => {
        const currentDevisIndex = devis.findIndex((c) => c.IDDevis === devisId) - 1
        const nextDevis = devis.at(currentDevisIndex)
        if (nextDevis) {
          setDevisId(nextDevis.IDDevis)
        }
      }}
      isOpen={isOpen}
      loading={loading}
      actions={[]}
      onActionClick={() => {}}
      objectId={devisId}
      activityType={ESuiviObject.DEVIS}
    >
      <DevisFormContent id={devisId} onLoad={setLoading} />
    </NDFForm>
  )
}
