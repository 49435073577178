import { Button, Col, Dropdown } from "antd"
import { CaretDown, Plus } from "react-flaticons"
import { useDevisContext } from "../../context/devis.context"
import { NDFTable } from "../Table/NDFTable"
import { TotalDetails } from "../TotalDetails/TotalDetails"

const DevisFormTable = () => {
  return (
    <>
      <Col xs={24} className='devis-lines'>
        <NDFTable />
      </Col>
      <Col xs={24} xl={12}>
        <AddLinesDropdown />
      </Col>
      <Col xs={24} xl={12}>
        <TotalDetails />
      </Col>
    </>
  )
}

const AddLinesDropdown = () => {
  const { addLine, addTitle } = useDevisContext()

  return (
    <Dropdown
      overlayClassName='devis-row-actions'
      menu={{
        items: [
          {
            key: "1",
            label: "Ajouter une ligne",
            onClick: () => addLine(),
          },
          {
            key: "2",
            label: "Ajouter un titre",
            onClick: () => addTitle(""),
          },
        ],
      }}
      placement='bottom'
      trigger={["click"]}
    >
      <Button className='add-line' icon={<Plus size={15} />}>
        Ajouter une nouvelle ligne <CaretDown size={15} />
      </Button>
    </Dropdown>
  )
}

export default DevisFormTable
