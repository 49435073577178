import { IRow } from "../models/IDevis"

interface ITaxAmountByName {
  [key: string]: number
}

export default class DevisUtils {
  /**
   * Get the total price of the devis
   * By summing the price of each line, multiplied by the quantity and reduced by the discount
   *
   * @returns the total price of the devis
   */
  static getDevisTotal = (lines: IRow[]) => {
    return lines
      .filter((line) => !line.isTitle)
      .map(
        (line) =>
          line.price * line.quantity - line.discount.calculateDiscount(line.price * line.quantity),
      )
      .reduce((acc, curr) => acc + curr, 0)
  }

  /**
   * Sum the total price of each tax by name
   *
   * @returns an object with the sum of the total price of each tax by name
   */
  static sumTotalsByTaxName = (lines: IRow[]): ITaxAmountByName => {
    return lines.reduce((acc: { [key: string]: number }, line) => {
      if (line.isTitle || line.tax.taxeCoeff === 0) {
        return acc
      }
      const taxName = `${line.tax.taxeName} (${line.tax.taxeCoeff}%)`
      const price =
        (line.price * line.quantity - line.discount.calculateDiscount(line.price * line.quantity)) *
        (line.tax.taxeCoeff / 100)
      acc[taxName] = acc[taxName] ? acc[taxName] + price : price
      return acc
    }, {})
  }

  /**
   * Get the total price of the devis with taxes
   *
   * @returns the total price of the devis with taxes
   */
  static getDevisTotalWithTaxes = (taxAmountByName: ITaxAmountByName, subTotal: number) => {
    const cumulativeTaxAmount = Object.values(taxAmountByName).reduce((acc, curr) => acc + curr, 0)
    return subTotal + cumulativeTaxAmount
  }
}
