import { Descriptions, Flex } from "antd"
import { DescriptionsItemType } from "antd/es/descriptions"
import { useMemo } from "react"
import { useDevisContext } from "../../context/devis.context"
import DevisUtils from "../../utils/devis.utils"

export const TotalDetails = () => {
  const { lines } = useDevisContext()

  const priceDetails: DescriptionsItemType[] = useMemo(() => {
    const subTotal = DevisUtils.getDevisTotal(lines)
    const taxAmountByName = DevisUtils.sumTotalsByTaxName(lines)
    const total = DevisUtils.getDevisTotalWithTaxes(taxAmountByName, subTotal)

    const details: DescriptionsItemType[] = [getSubTotalLine(subTotal)]

    if (Object.values(taxAmountByName).length > 0) {
      details.push(getTaxDetailsLine(taxAmountByName))
    }

    return [...details, getTotalLine(total)]
  }, [lines])

  return <Descriptions bordered column={{ xs: 1, sm: 2 }} items={priceDetails} />
}

const getSubTotalLine = (subTotal: number) => {
  return {
    label: "Sous-total",
    children: `€ ${subTotal.toFixed(2)}`,
    span: 5,
  }
}

const getTaxDetailsLine = (taxSum: { [key: string]: number }) => {
  const taxNames = (
    <Flex vertical gap={5}>
      {Object.keys(taxSum).map((taxName, i) => (
        <div key={`tax-name-${i}`}>{taxName}</div>
      ))}
    </Flex>
  )
  const taxValues = (
    <Flex vertical gap={5}>
      {Object.values(taxSum).map((tax, i) => (
        <div key={`tax-value-${i}`}>{`€ ${tax.toFixed(2)}`}</div>
      ))}
    </Flex>
  )

  return {
    label: taxNames,
    children: taxValues,
    span: 4,
  }
}

const getTotalLine = (total: number) => {
  return {
    label: "TOTAL (€)",
    children: `€ ${total.toFixed(2)}`,
    span: 4,
  }
}
