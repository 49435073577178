import { Button, Divider, Form, Select, Spin } from "antd"
import { useEffect, useState } from "react"
import { PlusSmall } from "react-flaticons"
import { ContactAPI } from "../../../../services/Contacts.api"
import { ISearchContact, SearchContact } from "../../../../types/IContact"
import { ContactForm } from "../../../contact/components/ContactForm/ContactForm"
import { NDFSelectOptionType } from "../../../shared/form/components/select/NDFSelect"
import { useDevisContext } from "../../context/devis.context"
import Addresses from "./Addresses/Addresses"
import "./contactSearch.scss"

interface Props {
  label: string
  required?: boolean
}

export const ContactSearch: React.FC<Props> = ({ label, required }) => {
  const [contactNameIds, setContactNameIds] = useState<NDFSelectOptionType<ISearchContact>[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)

  const { setContact, contact } = useDevisContext()

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchContact(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  const searchContact = async (search: string) => {
    if (search && search.length && search.length < 3) {
      setLoading(false)
      return Promise.resolve()
    }
    return ContactAPI.searchForContact(search)
      .then((res) => {
        if (res.ok) {
          const contacts = res.data?.map((contact) => ({
            key: contact.IDTiers,
            label: contact.tiersDenomination,
            value: contact.tiersDenomination,
            data: contact,
          }))
          setContactNameIds(contacts)
          return contacts
        }
        return []
      })
      .finally(() => setLoading(false))
  }

  const onContactSelect = (contact: NDFSelectOptionType<ISearchContact>) => {
    const selectedContact = contactNameIds.find((c) => c.key === contact.key)?.data

    if (selectedContact) {
      setContact(selectedContact)
    }
  }

  return (
    <>
      <Form.Item
        className='contact-search'
        label={label}
        name='contact'
        rules={[{ required: true, message: "Veuillez sélectionner un contact" }]}
      >
        <Select<NDFSelectOptionType<ISearchContact>>
          labelInValue
          allowClear
          onClear={() => setContact({} as ISearchContact)}
          placeholder='Recherchez un contact'
          options={contactNameIds}
          notFoundContent={loading ? <Spin size='small' /> : "Aucun contact trouvé"}
          showSearch
          onSearch={setSearch}
          onSelect={(option) => onContactSelect(option)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider className='contact-select-divider' />
              <Button
                className='contact-select-add-new-button'
                type='text'
                icon={<PlusSmall />}
                onClick={() => {
                  setShowModal(true)
                }}
              >
                Créer un nouveau contact
              </Button>
            </>
          )}
        />
      </Form.Item>
      {!!contact?.address?.length && (
        <Form.Item className='contact-addresses-form' label={" "} colon={false}>
          <Addresses />
        </Form.Item>
      )}
      <ContactForm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(contact) => {
          if (contact) {
            searchContact(contact.denomination).then((contacts) => {
              const foundContact = contacts?.find((c) => c.key === contact.IDTiers)
              setContact(foundContact?.data ?? new SearchContact(contact))
            })
          }
          setShowModal(false)
        }}
      />
    </>
  )
}
