import { create } from "zustand"
import { persist } from "zustand/middleware"
import { IBasicDevis } from "../models/IBasicDevis"

interface State {
  devis: IBasicDevis[]
  create: (devis: IBasicDevis[]) => void
  set: (devis: IBasicDevis[]) => void
  delete: () => void
}

export const useDevisStore = create<State>()(
  persist(
    (set) => ({
      devis: [],
      create: (devis) =>
        set({
          devis: [...devis],
        }),
      set: (newDevis) => set({ devis: newDevis }),
      delete: () => set({ devis: undefined }),
    }),
    { name: "NeelapsDevisFacturationStore" },
  ),
)

export const useDevis = () => {
  return useDevisStore((state) => state.devis)
}
