import { useClickAway } from "@uidotdev/usehooks"
import { useEffect, useRef, useState } from "react"
import ReactQuill from "react-quill"
import { SearchArticle } from "../../../../../types/ISearchArticle"
import { ArticleForm } from "../../../../article/components/ArticleForm/ArticleForm"
import NDFSelect from "../../../../shared/form/components/select/NDFSelect"
import ButtonEdit from "../../../../shared/utils/components/ButtonEdit/ButtonEdit"
import {
  focusTextEditor,
  RichTextEditor,
} from "../../../../shared/utils/components/RichTextEditor/RichTextEditor"
import { useDevisContext } from "../../../context/devis.context"
import { IRow } from "../../../models/IDevis"
import "./articleRow.scss"

interface Props {
  record: IRow
  onSelect?: (article: SearchArticle) => void
}

const ArticleRow: React.FC<Props> = ({ record, onSelect }) => {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false)
  const inputRef = useRef<ReactQuill>(null)

  const { articles, searchArticles, setArticleDescription, setArticleName } = useDevisContext()

  useEffect(() => {
    setLoading(true)

    const timeout = setTimeout(() => {
      searchArticlesByName(search)
    }, 500)

    return () => {
      clearTimeout(timeout)
      setLoading(false)
    }
  }, [search])

  useEffect(() => {
    setSearch(record.article.artName ?? "")
  }, [record.article.artName])

  const searchArticlesByName = (search: string) => {
    if (search.length && search.length < 3) {
      return
    }
    searchArticles(search).then(() => {
      setSearch(search)
      setLoading(false)
    })
  }

  const ref = useClickAway<HTMLDivElement>(() => {
    setIsDescriptionEditable(false)
  })

  const handleOpenModal = () => {
    if (!isDescriptionEditable) {
      setIsDescriptionEditable(true)
      focusTextEditor(inputRef)
    }
  }

  const handleSearch = (search: string) => {
    setArticleName(search, record.index)
    setSearch(search)
  }

  const quillModules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: ["#f50", "#2db7f5", "#87d068", "#108ee9", "#000"] }],
    ],
  }

  return (
    <>
      <NDFSelect<SearchArticle>
        className='article-select'
        onSearch={handleSearch}
        search={search}
        onSelect={(article) => {
          if (article && onSelect) {
            onSelect(article)
          }
        }}
        onClick={() => searchArticlesByName(search)}
        options={articles}
        onNew={() => setShowModal(true)}
        isLoading={loading}
      />
      {!isDescriptionEditable && record.article.artDesc?.length > 0 && (
        <div className='article-description' onClick={handleOpenModal}>
          <p
            className='article-description-body'
            dangerouslySetInnerHTML={{ __html: record.article.artDesc }}
          />
          <ButtonEdit />
        </div>
      )}
      {isDescriptionEditable && (
        <div ref={ref}>
          <RichTextEditor
            ref={inputRef}
            value={record.article.artDesc}
            onChange={(description) => setArticleDescription(description, record.index)}
            className='article-description-editor'
            modules={quillModules}
          />
        </div>
      )}

      <ArticleForm
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={(article) => {
          setShowModal(false)
          if (article) {
            searchArticlesByName(article.artName)
            onSelect &&
              onSelect(
                new SearchArticle({
                  ...article,
                  IDTaxe: article.taxe.IDTaxe,
                  IDSaleUnit: article.saleUnit.IDSaleUnit,
                  taxeCoeff: article.taxe.taxeCoeff,
                  taxeName: article.taxe.taxeName,
                  suName: article.saleUnit.suName,
                  suDisplayName: article.saleUnit.suDisplayName,
                }),
              )
          }
        }}
      />
    </>
  )
}

export default ArticleRow
