import { EArticleType } from "../../../types/EArticleType"
import { ISaleUnit } from "../../../types/ISaleUnit"
import { SearchArticle } from "../../../types/ISearchArticle"
import { ITaxe } from "../../../types/ITaxe"
import { EDevisStatus } from "./EDevisStatus"

export interface IDevis {
  IDDevis: number
  creatorName: string
  numPiece: string
  isModele: number
  datePiece: string
  dateValidite: null
  devisStatut: EDevisStatus
  devisTitle: string
  devisTotalHT: number
  devisTotalTaxes: null
  devisTotalTTC: null
  devisCGV: null
  IDTiers: number
  tiers_displayName: string
  tiers_formattedAddress: null
  tiers_adresse: null
  tiers_codePostal: null
  tiers_ville: null
  tiers_countryCode: null
  tiers_countryName: null
  _lignes: Ligne[][]
}

export interface IDevisCreate {
  IDTiers: number
  tiers_displayName: string
  IDBillingAddress: number
  IDDeliveryAddress: number
  datePiece: string
  dateExpiration?: string
  devisTitle?: string
  devisInfosClient?: string
  devisCGV?: string
  line: CLigneCreate[]
  // line[n][LigneCreate.keys] - needed - https://elapida.atlassian.net/browse/NDF-48
}

export enum EDevisLineType {
  ARTICLE = "art",
  TITLE = "title",
}

export interface LigneCreate {
  IDDevisLigne?: number // only on update
  IDArt: number
  IDTaxe: number
  IDSaleUnit: number
  lineNum: number
  lineType: EDevisLineType
  artRef?: string
  artType: string
  artName?: string
  artDesc?: string
  artPVHT?: number
  artQuant?: number
  remiseValeur?: number // e.g. 10 for 10% or 10€
  remiseType?: string // e.g. P for percent or V for value
}
export class CLigneCreate implements LigneCreate {
  IDDevisLigne?: number // only on update
  IDArt: number
  IDTaxe: number
  IDSaleUnit: number
  lineNum: number
  lineType: EDevisLineType
  artRef?: string
  artType: string
  artName?: string
  artDesc?: string
  artPVHT?: number
  artQuant?: number
  remiseValeur?: number // e.g. 10 for 10% or 10€
  remiseType?: string // e.g. P for percent or V for value

  constructor(ligne: IRow) {
    this.IDDevisLigne = ligne?.id
    this.IDArt = ligne.article.IDArt
    this.IDTaxe = ligne.tax.IDTaxe
    this.IDSaleUnit = ligne.unit.IDSaleUnit
    this.lineNum = ligne.index
    this.lineType = ligne.isTitle ? EDevisLineType.TITLE : EDevisLineType.ARTICLE
    this.artRef = ligne.article.artRef
    this.artType = ligne.article?.artType ?? EArticleType.MARCHANDISE
    this.artName = ligne.article.artName ?? ligne.title
    this.artDesc = ligne.article.artDesc
    this.artPVHT = ligne.price
    this.artQuant = ligne.quantity
    this.remiseValeur = ligne.discount.value
    this.remiseType = ligne.discount.type
  }

  isValid() {
    return ![this.IDTaxe, this.IDSaleUnit, this.lineNum, this.artType].some(
      (value) => value === undefined,
    )
  }
}
interface Ligne {
  IDDevisLigne: number
  IDArt: number
  IDTaxe: number
  IDSaleUnit: number
  numChapitre: number
  numLigne: number
}

export class DiscountType {
  static PERCENT = "P"
  static AMOUNT = "V"
}

export class Discount {
  type: string
  value: number

  constructor(type: DiscountType, coefficient: number) {
    this.type = type.toString()
    this.value = coefficient
  }

  calculateDiscount(price: number) {
    return this.type === DiscountType.PERCENT ? price * (this.value / 100) : this.value
  }

  getType() {
    return this.type === DiscountType.PERCENT ? "%" : "€"
  }

  getRealType() {
    return this.type === DiscountType.PERCENT ? DiscountType.PERCENT : DiscountType.AMOUNT
  }

  private static getLabel(type: string) {
    return type === DiscountType.PERCENT ? "%" : "€"
  }

  static getOptions() {
    return [
      { value: DiscountType.PERCENT, label: Discount.getLabel(DiscountType.PERCENT) },
      { value: DiscountType.AMOUNT, label: Discount.getLabel(DiscountType.AMOUNT) },
    ]
  }
}

export interface IRow {
  id?: number
  key: string

  index: number

  title?: string
  isTitle: boolean

  article: SearchArticle

  quantity: number

  unit: ISaleUnit

  price: number
  discount: Discount

  tax: ITaxe
}
